import styled from "styled-components";
import { device } from "./deviceSizes";
export const EnquirePageStyles = styled.div`
  padding-top: 7rem;
  background: white;
  width: 100%;
  height: 100%;

  .content {
    background: white;
    width: 100%;
    padding: 10rem 5rem;
    height: 100%;
    color: black;
    display: grid;
    grid-template-columns: 1fr 2fr;
    &.full-form {
      grid-template-columns: 1fr;
    }

    @media ${device.onlyMobileS} {
      grid-template-columns: 1fr;
      padding: 10rem 0rem;
      .submit-btn {
        align-self: center;
      }
    }
    .main-div {
      padding: 3rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: center;
      @media ${device.onlyMobileSm} {
        padding: unset;
      }
    }

    .form-main-div {
      padding: 3rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    h2 {
      color: #000000;
      font-size: 30px;
      letter-spacing: 5px;
      font-weight: bold;
      text-transform: capitalize;
      text-align: center;
    }

    .go-back-button {
      margin: 0;
      display: flex;
      justify-content: center;
    }

    .carousel-wrapper {
      display: flex;
      width: 40rem;
      height: auto;

      @media ${device.onlyMobileSm} {
        margin-bottom: 5rem;
      }
      @media ${device.onlyTablet} {
        width: 50rem;
      }

      @media ${device.mobileSmall} {
        width: 35rem;
      }
      @media ${device.mobileS} {
        width: 30rem;
      }
    }

    .carousel {
      margin-top: 5rem;
      width: 100%;
      .image-container {
        display: block;
        width: 100%;
        height: auto;
      }

      img {
        height: 40vh;
        width: 100%;
      }
      .slick-track {
        display: flex;
      }

      .slick-arrow {
        z-index: 1;
        top: 45%;
        ::before {
          content: "" !important;
        }
      }
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }
      .slick-dots {
        display: flex;
        justify-content: center;
        font-size: 10px !important;
        li.slick-active button::before {
          border: 1px solid;
          border-radius: 50%;
        }
      }
    }

    .header-content {
      display: flex;
      flex-direction: column;
      .villa-name {
        text-align: center;
        color: #000000;
        font-weight: 300;
        margin: 16px;
        align-self: center;
      }
      .resort-name {
        font-size: 22px;
        text-align: center;
        align-self: center;
      }

      .divider {
        align-self: center;
        border-bottom: 2px solid #ababab;
        margin-bottom: 1rem;
        width: 20%;
      }
      .villa-features {
        display: flex;
        margin: 2rem 20% 0% 20%;
        justify-content: space-between;
        font-size: 12px;
        svg {
          width: 3rem;
          height: 3rem;
        }

        li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .icon-label {
          margin-top: 1rem;
        }
      }
      margin-top: 5rem;
      /* @media ${device.onlyMobileS} {
        margin-top: 5rem;
      } */
    }
  }

  @media ${device.onlyMobileSm} {
    padding-top: 0;
    .content {
      padding-top: 1rem;
    }
  }

  form {
    width: 100%;
    height: 100%;
    color: black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media ${device.onlyMobileS} {
      grid-template-columns: 1fr;
    }
    @media ${device.tablet} {
      flex-direction: column;
      /* margin: 1rem 0; */
    }

    .holidays-details-section,
    .yours-details-section {
      background: #f5f5f5;
      .two-column {
        display: grid;
        grid-template-columns: 48% 48%;
        @media ${device.onlyMobile} {
          grid-template-columns: 1fr;
        }
        gap: 4%;
        label {
          text-transform: uppercase;
          color: #3d3d3d;
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
      }

      .three-column {
        display: grid;
        grid-template-columns: 48% 1fr 1fr;
        @media ${device.onlyMobile} {
          grid-template-columns: 1fr;
        }

        gap: 4%;
        .phone {
          display: flex;

          select {
            width: 10rem;
            margin-right: 1rem;
          }
        }
      }

      .four-column {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media ${device.onlyMobile} {
          grid-template-columns: 1fr;
        }
        gap: 4%;
      }
    }

    .holidays-details-section {
      border-right: 2px solid white;
      .form-content {
        margin-top: 5rem;
      }
    }
    .yours-details-section {
      .form-content {
        margin-top: 5rem;
      }
    }
    .form-control {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;

      @media ${device.tablet} {
        width: 100%;
        /* margin: 1rem 0; */
      }
    }

    /* & */

    label {
      text-transform: uppercase;
      color: #3d3d3d;
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    input,
    select {
      @supports (-webkit-touch-callout: none) {
        -webkit-appearance: none;
      }

      width: 100%;
      padding: 1rem 1.5rem;
    }
    textarea {
      @supports (-webkit-touch-callout: none) {
        -webkit-appearance: none;
      }
      height: 15rem;
    }

    span.required {
      color: var(--red);
    }
    .react-tel-input {
      .form-control {
        border-radius: unset;
        line-height: unset;
        border-color: rgb(118, 118, 118);
        height: unset;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
      }
      .flag-dropdown {
        border: 1px solid rgb(118, 118, 118);
      }
    }
  }
  .react-datepicker,
  .react-datepicker__month-container,
  .react-datepicker__month {
    width: 100%;
    height: 100%;
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 12px;
    margin-right: 0.4;
  }

  .react-datepicker__day-names {
    margin: 0.4rem;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-calendar {
    width: 300px;
    height: 300px;
  }
`;
